<template>
  <div>
    <b-overlay center :show="isLoading" rounded="sm">
      <div v-if="!isLoading">
        <details-card
          v-for="(item, index) in detailFields"
          :key="index"
          :header="item.header"
          :edit="item.edit || false"
          :fields="item.fields"
          :beForm="beForm"
          :data="details"
          :repository="item.repository"
          @update="onUpdate"
        >
          <!-- :labels="labels" -->
        </details-card>
      </div>
      <template #overlay>
        <div class="text-center mt-5">
          <base-icon name="loading" width="35" height="35" />
          <p id="cancel-label">Operazione in corso...</p>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import BaseIcon from "@/components/BaseIcon";
import ShowMixin from "@/mixins/ShowMixin";
import FormMixin from "@/mixins/FormMixin";
import { RepositoryFactory } from "@/repositories/RepositoryFactory";
import DetailsCard from "@/components/DetailsCard";

export default {
  mixins: [FormMixin, ShowMixin],
  name: "General",
  props: {
    resourceId: Number,
  },
  components: {
    BaseIcon,
    DetailsCard,
  },
  data() {
    return {
      isLoading: false,
      repository: "payment_procedure",
      allRepositories: [],
      details: null,
      defaultLabels: {},
      options_exclusion_type: [],
      mandatoryFields: {
        payment_procedure: {},
      },
      detailFields: {
        veicolo: {
          header: "Dati Generali",
          edit: true,
          repository: "payment_procedure",
          fields: [
            {
              label: this.getDictionary("processing_at", "payment_procedure"),
              type: "datepicker",
              value: "processing_at",
              rules: {
                required: true,
              },
            },
            {
              label: this.getDictionary("iban", "payment_procedure"),
              value: "iban",
              rules: {
                required: true,
              },
            },
            {
              label: this.getDictionary("identifier", "payment_procedure"),
              value: "identifier",
              rules: {
                required: true,
              },
            },
            {
              label: this.getDictionary("gross", "payment_procedure"),
              type: "currency",
              value: "gross",
              rules: {
                required: true,
              },
            },
            {
              label: this.getDictionary("exclusion_type", "payment_procedure"),
              type: "select",
              options: [
                {
                  value: 0,
                  text: "Escluso",
                },
                {
                  value: 1,
                  text: "In partenza",
                },
              ],
              value: "exclusion_type.text",
              option: "exclusion_type",
              rules: {
                required: true,
              },
            },
            {
              label: this.getDictionary("first_attempt", "payment_procedure"),
              type: "select",
              options: [
                { value: "N", text: "No" },
                { value: "Y", text: "Sì" },
              ],
              value: "first_attempt.text",
              option: "first_attempt",
              rules: {
                required: true,
              },
            },
          ],
        },
      },
    };
  },
  methods: {
    fetchForm() {
      this.isLoading = true;
      // let queryString = `relations/byAddressBook/byCorrespondence`;
      this.fetchShowForm(this.repository, this.resourceId)
        .then((response) => {
          const data = response.data.data;
          this.details = data;
          this.fetchEditForm(this.repository, this.resourceId)
            .then(() => {
              this.initDetailCardFormData();
              this.isLoading = false;
            })
            .catch((error) => {
              let errMsg = this.$getErrorMessage(error);
              this.$showSnackbar({
                preset: "error",
                text: `${errMsg}`,
              });
            });
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
        });
    },
    onUpdate(form, repository) {
      this.isLoading = true;
      const Repo = RepositoryFactory.get(repository);

      let payload = {};
      payload = { ...this.mandatoryFields[repository] };

      // for (const key in payload) {
      //   if (
      //     payload[key] === null ||
      //     payload[key] === undefined ||
      //     payload[key] === ""
      //   ) {
      //     if (![null, undefined].includes(this.beForm[repository][key].value)) {
      //       payload[key] = this.beForm[repository][key].value;
      //     }
      //     if (this.beForm[repository][key] && ![null, undefined].includes(this.beForm[repository][key].value)) {
      //       payload[key] = this.beForm[repository][key].value;
      //     }
      //   }
      //   // if (this.beForm[repository][key].type === "select") {
      //   //   payload[key] = this.beForm[repository][key].value;
      //   // }
      // }

      this.setMandatoryValues(repository, payload);

      payload = { ...payload, ...form };
      // let resId =
      //   repository === this.repository
      //     ? this.resourceId
      //     : this.repositoryResourceId[repository].id;
      // if (repository === "address_book") {
      //   resId = addressBookId;
      // }
      // console.log(resId);
      // console.log(payload);
      Repo.update(this.resourceId, payload).then(() => {
        this.$emit("fetch");
        this.fetchForm();
      });
    },
  },
  computed: {},
  mounted() {
    this.fetchForm();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
